<template>
  <a :href="link" target="_blank" :title="$t('support')">
    <a-icon type="question-circle" class="text-muted font-size-14"/>
  </a>
</template>

<script>
export default {
  name: 'HelpIcon',
  props: [
    'link',
  ],
}
</script>
