<template>
  <!-- Danger -->
  <div
    :class="$style.root"
    class="pt-4 pl-4 overflow-hidden position-relative bg-danger line-height-1 d-block"
    v-if="danger">
    <h2 role="button"
      :class="$style['link-danger']"
      class="font-size-48 font-weight-bold text-white"
      @click="$emit('click')">
      {{ value | k }}
    </h2>
    <span class="font-size-18 text-white">
      {{ title }}
      <j-help :link="help" />
    </span>
    <span :class="$style.icon">
      <i class="fe text-white" :class="icon" />
    </span>
  </div>
  <!-- Default -->
  <div
    :class="$style.root"
    class="pt-4 pl-4 overflow-hidden position-relative bg-light line-height-1 d-block"
    v-else>
    <h2 role="button"
      :class="$style['link-default']"
      class="font-size-48 font-weight-bold"
      @click="$emit('click')">
      {{ value | k }}
    </h2>
    <span class="font-size-18">
      {{ title }}
      <j-help :link="help" />
    </span>
    <span :class="$style.icon">
      <i class="fe text-gray-4" :class="icon" />
    </span>
  </div>
</template>
<script>
import JHelp from '@/views/shared/labels/help'
export default {
  name: 'JTotal',
  props: {
    icon: String,
    title: String,
    value: Number,
    danger: Boolean,
    help: String,
  },
  components: {
    JHelp,
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
