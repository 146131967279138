<template>
  <div>
    <!-- Chart -->
    <div v-if="visible">
      <div class="font-weight-bold text-dark font-size-18">
        {{ $t('msg.metrics.mh.title') }}
        <j-help link="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#volumen-mensual" />
      </div>
      <div>
        <i class="fe fe-arrow-up text-success font-weight-bold"  v-if="growth > 0" />
        <i class="fe fe-arrow-down text-danger font-weight-bold" v-if="growth < 0" />
        {{ growth | percent }}% {{ $t('msg.metrics.mh.subtitle') }}
      </div>
      <vue-chartist
        class="chart ct-hidden-points"
        type="Line"
        :data="history"
        :options="options" />
    </div>
    <!-- Default -->
    <a-result
      :title="$t('msg.metrics.mh.empty')"
      v-else>
      <template #icon>
        <img width="100" src="/resources/images/chart.png"/>
      </template>
    </a-result>
  </div>
</template>
<script>
import Moment from 'moment'
import JHelp from '@/views/shared/labels/help'
import data from './data.json'
import VueChartist from 'v-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
export default {
  name: 'JMonthlyHistory',
  props: {
    metrics: Object,
  },
  components: {
    JHelp,
    'vue-chartist': VueChartist,
  },
  data: function () {
    const options = {
      chartPadding: {
        right: 20,
        left: 0,
        top: 5,
        bottom: 5,
      },
      fullWidth: true,
      showPoint: true,
      lineSmooth: false,
      axisY: {
        showGrid: false,
        showLabel: false,
      },
      axisX: {
        showGrid: true,
        showLabel: true,
        offset: 20,
      },
      showArea: true,
      plugins: [ChartistTooltip({ anchorToPoint: false, appendToBody: true, seriesName: false })],
    }

    return {
      data,
      options,
    }
  },
  computed: {
    series() {
      return this.metrics.history.filter(i => !i.kind || i.kind === 0).map(i => i.total)
    },
    visible() {
      return this.series.length > 1
    },
    growth() {
      return this.metrics.monthly_volume.growth
    },
    history() {
      return {
        labels: Array.from(new Set(this.metrics.history.map(i => i.month))).map(i => `${Moment(i).format('MMM')} '${Moment(i).format('YY')}`),
        series: [
          {
            name: 'total',
            meta: this.$t('msg.metrics.mh.total'),
            className: 'ct-series-a',
            data: this.series,
          },
        ],
      }
    },
  },
}
</script>
<style scoped>
.chart {
  height: 215px;
}
</style>
