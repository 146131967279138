<template>
  <div>
    <div class="row">
      <!-- Refresh -->
      <!-- <div class="col-lg-12">
        <div class="text-right font-size-12 mb-1">
          <span class="mr-2">{{ updated | elapsed | capital }}</span>
          <a-button icon="sync" type="default" shape="circle" size="small" @click="fetch" />
        </div>
      </div> -->
      <!-- Metrics -->
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <j-metric
                  icon="fe-package"
                  :title="$t('msg.metrics.dv.title')"
                  :tooltip="$t('msg.metrics.dv.tooltip')"
                  :growth="metrics.daily_volume.growth"
                  help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#total-de-hoy">
                  {{ metrics.daily_volume.total | number }}
                </j-metric>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <j-metric
                  icon="fe-calendar"
                  :title="$t('msg.metrics.mv.title')"
                  :tooltip="$t('msg.metrics.mv.tooltip')"
                  help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#total-del-mes">
                  {{ metrics.monthly_volume.total | number }}
                </j-metric>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <j-metric
                  icon="fe-watch"
                  :title="$t('msg.metrics.lt.title')"
                  suffix="hs"
                  help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#lead-time">
                  {{ metrics.lead_time.total | hs }}
                </j-metric>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
             <div class="card">
              <div class="card-body">
                <j-metric
                  icon="fe-check-square"
                  :title="$t('msg.metrics.su.title')"
                  suffix="%"
                  help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#eficacia">
                  {{ metrics.success.total | percent }}
                </j-metric>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Banners -->
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 col-xl-7 d-none d-xl-block">
            <div class="card">
              <div class="card-body">
                <j-banner />
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-5">
             <div class="card">
              <div class="card-body">
                <j-carousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Chart -->
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 col-xl-5">
             <div class="card">
              <div class="card-body px-4 py-3">
                <div class="row">
                  <div class="col-12 col-md-6 px-2 py-1">
                    <j-total
                      icon="fe-printer"
                      :title="$t('msg.metrics.counters.pending')"
                      :value="metrics.count.pending"
                      help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#pendientes"
                      @click="() => onSelectShortcut('PENDING', 'NEWEST')" />
                  </div>
                  <div class="col-12 col-md-6 px-2 py-1">
                    <j-total
                      icon="fe-truck"
                      :title="$t('msg.metrics.counters.active')"
                      :value="metrics.count.active"
                      help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#activos"
                      @click="() => onSelectShortcut('ACTIVE', 'NEWEST')" />
                  </div>
                  <div class="col-12 col-md-6 px-2 py-1">
                    <j-total
                      icon="fe-clock"
                      :title="$t('msg.metrics.counters.delayed')"
                      :value="metrics.count.delayed"
                      help="https://academy.jipink.com/docs/metricas/?utm_campaign=adoption&utm_source=desk&utm_medium=help#demorados"
                      @click="() => onSelectShortcut('DELAYED', 'OLDEST')" />
                  </div>
                  <div class="col-12 col-md-6 px-2 py-1">
                    <j-total
                      icon="fe-lock"
                      :title="$t('msg.metrics.counters.blocked')"
                      :value="metrics.count.blocked"
                      :danger="metrics.count.blocked > 0"
                      help="https://academy.jipink.com/docs/seguimiento/gestion-de-bloqueados/?utm_campaign=adoption&utm_source=desk&utm_medium=help"
                      @click="() => onSelectShortcut('BLOCKED', 'NEWEST')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-7">
            <div class="card">
              <div class="card-body">
                <j-monthly :metrics="metrics" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Flex -->
      <div class="col-lg-12" v-if="metrics.flex">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <j-flex
                  icon="fe-camera-off"
                  :title="$t('enum.flex.FLEX_NOT_PICKED')"
                  :value="metrics.flex.not_picked"
                  @click="key => onSelectShortcut('FLEX_NOT_PICKED', 'NEWEST')"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <j-flex
                  icon="fe-bell-off"
                  :title="$t('enum.flex.FLEX_NOT_MARKED')"
                  :value="metrics.flex.not_marked"
                  @click="key => onSelectShortcut('FLEX_NOT_MARKED', 'NEWEST')"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <j-flex
                  icon="fe-x-square"
                  :title="$t('enum.flex.FLEX_NOT_DELIVERED')"
                  :value="metrics.flex.not_delivered"
                  @click="key => onSelectShortcut('FLEX_NOT_DELIVERED', 'NEWEST')"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import acl from '@/auth/acl'
import lms from '@/api/lms'
import JFlex from './flex'
import JMetric from './metric'
import JBanner from './banner'
import JCarousel from './carousel'
import JMonthly from './monthly'
import JTotal from './total'

export default {
  components: {
    JFlex,
    JMetric,
    JBanner,
    JCarousel,
    JMonthly,
    JTotal,
  },
  data: function () {
    return {
      acl,
    }
  },
  computed: {
    metrics() {
      return this.$store.getters['metrics/PAYLOAD']
    },
    updated() {
      return this.$store.getters['metrics/UPDATED']
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      return lms.metrics.getMetrics()
        .then(response => this.$store.commit('metrics/SET_PAYLOAD', response.data))
    },
    onSelectShortcut(shortcut, sort) {
      this.$store.commit('shipments/PUT_FILTERS', { shortcut, sort })
      this.$router.push('shipments')
    },
  },
}
</script>
