<template>
  <div class="d-flex flex-wrap align-items-center">
    <div class="my-1 mr-4 font-size-36 flex-shrink-0">
      <i class="fe" :class="icon"/>
    </div>
    <div class="mr-auto">
      <p class="text-uppercase text-dark font-weight-bold mb-1">
        {{ title }}
      </p>
      <p class="text-gray-5 mb-0">
        Mercado Envíos Flex
      </p>
    </div>
    <p role="button"
      class="link font-weight-bold font-size-32 mb-0"
      :class="{ 'text-danger': value > 0, 'text-success': value == 0 }"
      @click="$emit('click')">
      {{ value | k }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'FlexMetric',
  props: {
    title: String,
    value: Number,
    icon: String,
  },
}
</script>
<style lang="scss" scoped>
@import '@/components/mixins.scss';

.link:hover {
  text-decoration: underline;
  text-decoration-color: $primary;
}
</style>
