<template>
  <div>
    <div class="d-flex flex-nowrap align-items-center">
      <div class="mr-auto">
        <div class="font-size-18">
          {{ title }}
          <j-help :link="help" />
        </div>
        <div class="font-weight-bold font-size-36 text-dark">
          <slot></slot>
          <span class="font-size-18 font-weight-light align-middle" v-if="suffix">
            {{ suffix }}
          </span>
          <a-tooltip placement="right">
            <template slot="title">
              <span>{{ tooltip }}</span>
            </template>
            <span class="font-size-16 text-danger align-middle" v-if="growth < 0">
              {{ growth | percent }}%
            </span>
            <span class="font-size-16 text-success align-middle" v-if="growth > 0">
              +{{ growth | percent }}%
            </span>
          </a-tooltip>
        </div>
      </div>
      <div class="icon">
         <i class="fe font-size-24 text-light" :class="icon" />
      </div>
    </div>
  </div>
</template>
<script>
import JHelp from '@/views/shared/labels/help'
export default {
  name: 'Metric',
  props: {
    icon: String,
    title: String,
    growth: Number,
    suffix: String,
    tooltip: String,
    help: String,
  },
  components: {
    JHelp,
  },
}
</script>
<style lang="scss" scoped>
@import '@/components/mixins.scss';
.icon {
  padding: 10px 12px;
  background: $gradient;
  border-radius: 10px;
}
</style>
